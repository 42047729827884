<template>
  <div>
    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-descriptions title="信用额度记录" />

        <a-button
          type="primary"
          @click="isShowNewModal = true"
          v-if="isShowMenu('agent_credit_histories/new')"
        >
          分配额度</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 分配额度模态框 -->
    <new-agent-credit-history
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import { findAgentCreditHistories } from '@/api/agent_credit_history'
import { hasPermission } from '@/utils/permission'
import { formatFloat } from '@/utils/filter'

export default {
  name: 'AgentCreditHistories',
  components: {
    Pagination,
    NewAgentCreditHistory: () => import('@/views/agent_credit_histories/New')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示设置分配额度表单弹窗
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '时间',
          dataIndex: 'created_at',
          scopedSlots: { customRender: 'username' }
        },
        {
          title: '分配额度(元)',
          dataIndex: 'credit',
          customRender: formatFloat,
          scopedSlots: { customRender: 'credit' }
        },
        {
          title: '分配人',
          dataIndex: 'operator_name'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        }
      ]
    },
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    fetchData() {
      this.loading = true
      const data = Object.assign({ agent_id: this.agentId }, this.query)
      findAgentCreditHistories(data).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
