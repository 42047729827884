import request from '@/utils/request'
// 查询信用额度
export function findAgentCreditHistories(params) {
  return request({
    url: `/agent_credit_histories`,
    method: 'get',
    params: params
  })
}

// 创建信用额度
export function createAgentCreditHistories(data) {
  return request({
    url: `/agent_credit_histories`,
    method: 'post',
    data
  })
}

// 查询信用额度表单记录
export function findAgentCreditHistoriesForm(agentId) {
  return request({
    url: `/agent_credit_histories/current`,
    method: 'get',
    params: { agent_id: agentId }
  })
}
