<template>
  <div>
    <a-spin :spinning="loading">
      <a-row>
        <a-col :span="3">
          <a-descriptions title="财务权限" :column="1" class="simiot-descriptions simiot-descriptions-max-8" /></a-col>

        <a-col :span="8">
          <a-descriptions :column="1" class="simiot-descriptions simiot-descriptions-max-8">
            <a-descriptions-item label="是否计入报表统计">
              <a-switch
                :checked="settings.is_report"
                :loading="editingIsReport"
                @change="changeIsReport"
                :disabled="!isShowMenu('agents/is_report')"
              />
            </a-descriptions-item> </a-descriptions></a-col>

        <a-col :span="8">
          <a-descriptions :column="1" class="simiot-descriptions simiot-descriptions-max-8">
            <a-descriptions-item label="后付权限">
              <a-switch
                :checked="settings.is_after_pay_enabled"
                :loading="editingIsAfterPayEnabled"
                @change="changeIsAfterPayEnabled"
                :disabled="!isShowMenu('agents/is_after_pay_enabled')"
              />
            </a-descriptions-item> </a-descriptions></a-col>
      </a-row>

      <a-row>
        <a-col :span="3">
          <a-descriptions title="VPDN权限" :column="1" class="simiot-descriptions simiot-descriptions-max-8" /></a-col>

        <a-col :span="8">
          <a-descriptions :column="1" class="simiot-descriptions simiot-descriptions-max-8">
            <a-descriptions-item label="白名单豁免">
              <a-switch
                :checked="settings.is_white_list_exemption"
                :loading="editingIsWhiteListExemption"
                @change="changeIsWhiteListExemption"
                :disabled="!isShowMenu('agents/is_white_list_exemption')"
              />
            </a-descriptions-item> </a-descriptions></a-col>

        <a-col :span="8">
          <a-descriptions :column="1" class="simiot-descriptions simiot-descriptions-max-8">
            <a-descriptions-item label="定转通权限">
              <a-switch
                :checked="settings.is_fixed_transfer"
                :loading="editingIsFixedTransfer"
                @change="changeIsFixedTransfer"
                :disabled="!isShowMenu('agents/is_fixed_transfer')"
              />
            </a-descriptions-item> </a-descriptions></a-col>
      </a-row>
    </a-spin>

    <!--    信用额度记录-->
    <agent-credit-histories
      :style="{ marginTop: '20px' }"
      v-if="isShowMenu('agent_credit_histories')"
    />
  </div>
</template>

<script>
import AgentCreditHistories from '@/views/agent_credit_histories/index'
import { hasPermission } from '@/utils/permission'
import {
  findAgentSettings,
  UpdateAgentSetting,
  updateAgentIsAfterPayEnabled
} from '@/api/agent'

export default {
  name: 'AgentSetting',
  components: {
    AgentCreditHistories
  },
  data() {
    return {
      loading: true,
      editingIsReport: false,
      editingIsWhiteListExemption: false,
      editingIsFixedTransfer: false,
      editingIsAfterPayEnabled: false,
      settings: {}
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchSettingsData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    fetchSettingsData() {
      this.loading = true
      findAgentSettings(this.agentId).then((res) => {
        if (res.code === 0) {
          this.settings = res.data
        }
        this.loading = false
      })
    },

    // 更新是否计入报表统计
    changeIsReport(isReport) {
      const vm = this
      this.$confirm({
        title: isReport ? '确定计入报表统计吗?' : '确定不计入报表统计吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingIsReport = true
          UpdateAgentSetting(vm.agentId, { value: isReport, name: 'is_report' })
            .then((res) => {
              vm.editingIsReport = false
              if (res.code === 0) {
                vm.settings.is_report = isReport
              }
            })
            .catch(() => {
              vm.editingIsReport = false
            })
        }
      })
    },

    // 更新是否开启后付权限
    changeIsAfterPayEnabled(isAfterPayEnabled) {
      const vm = this
      this.$confirm({
        title: isAfterPayEnabled ? '确定开启后付权限吗?' : '确定关闭后付权限吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingIsAfterPayEnabled = true
          updateAgentIsAfterPayEnabled(vm.agentId, {
            is_after_pay_enabled: isAfterPayEnabled
          })
            .then((res) => {
              vm.editingIsAfterPayEnabled = false
              if (res.code === 0) {
                vm.settings.is_after_pay_enabled = isAfterPayEnabled
              }
            })
            .catch(() => {
              vm.editingIsAfterPayEnabled = false
            })
        }
      })
    },

    // 更新白名单豁免
    changeIsWhiteListExemption(isWhiteListExemption) {
      const vm = this
      this.$confirm({
        title: isWhiteListExemption
          ? '确定开启白名单豁免权限吗?'
          : '确定关闭白名单豁免权限吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingIsWhiteListExemption = true
          UpdateAgentSetting(vm.agentId, {
            value: isWhiteListExemption,
            name: 'is_white_list_exemption'
          })
            .then((res) => {
              vm.editingIsWhiteListExemption = false
              if (res.code === 0) {
                vm.settings.is_white_list_exemption = isWhiteListExemption
              }
            })
            .catch(() => {
              vm.editingIsWhiteListExemption = false
            })
        }
      })
    },

    // 更新定转通权限
    changeIsFixedTransfer(isFixedTransfer) {
      const vm = this
      this.$confirm({
        title: isFixedTransfer ? '确定开启定转通权限吗?' : '确定关闭定转通权限吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingIsFixedTransfer = true
          UpdateAgentSetting(vm.agentId, {
            value: isFixedTransfer,
            name: 'is_fixed_transfer'
          })
            .then((res) => {
              vm.editingIsFixedTransfer = false
              if (res.code === 0) {
                vm.settings.is_fixed_transfer = isFixedTransfer
              }
            })
            .catch(() => {
              vm.editingIsFixedTransfer = false
            })
        }
      })
    }
  }
}
</script>
